@use '../Global/' as *;

.p-notfound {
  margin-top: 100px;

  @include MQ('md') {
    margin-top: 40px;
  }
}

.p-notfound__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;

  @include MQ('md') {
    align-items: flex-start;
    row-gap: 24px;
  }
}

.p-notfound__title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
  text-align: center;

  @include MQ('md') {
    text-align: left;
  }
}

.p-notfound__text {
  font-size: rem(20);
  text-align: center;

  @include MQ('md') {
    text-align: left;
  }
}
