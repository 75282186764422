@use '../Global/' as *;

.l-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  background-color: var(--u-color-100);
  padding: 10px 16px 10px 36px;
  border-radius: 24px 0 0 0;

  @include MQ(md) {
    width: 100%;
    right: unset;
    left: 0;
    justify-content: center;
    padding: 14px 10px;
    border-radius: 0;
  }
}

.l-banner__label {
  margin-right: 28px;
  font-size: rem(20);
  font-weight: 600;
  letter-spacing: 0.04em;
  color: var(--mainColor);

  @include MQ(md) {
    margin-right: 20px;
  }
}

.l-banner-sns {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;

  @include MQ(md) {
    column-gap: 20px;
  }
}

.l-banner-sns__item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  @include MQ(md) {
    width: 30px;
    height: 30px;
  }
}

.l-banner-sns__link {
  display: flex;
  transition: opacity .3s;

  &:hover {
    opacity: .7;
  }
}

.l-banner__text {
  margin-left: 34px;
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.35;
  letter-spacing: 0.04em;
  color: var(--mainColor);
  position: relative;

  @include MQ(md) {
    margin-left: 48px;
    font-size: rem(12);
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -24px;
    transform: translateY(-50%);
    width: 12px;
    height: 20px;
    background-image: url('../images/common/arrow-green.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }
}