@use '../Global/' as *;

.c-lower-heading {
  margin-top: 64px;
  display: flex;
  align-items: center;
  column-gap: 40px;

  @include MQ('md') {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 16px;
  }
}

.c-lower-heading__main {
  flex-shrink: 0;
  width: 295px;
  display: flex;
  flex-direction: column-reverse;
  position: relative;

  @include MQ('md') {
    width: 100%;
    padding-bottom: 16px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 2px;
    height: 100%;
    background-color: var(--black);

    @include MQ('md') {
      inset: unset;
      bottom: 0;
      transform: none;
      width: 50px;
      height: 1px;
    }
  }
}

.c-lower-heading__title {
  font-family: $font-familyLogo;
  font-size: rem(20);
  font-weight: 500;

  @include MQ('md') {
    font-size: rem(14);
  }
}

.c-lower-heading__label {
  font-family: $font-familyLato;
  font-size: rem(40);

  @include MQ('md') {
    font-size: rem(32);
  }
}

.c-lower-heading__content {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.c-lower-heading__lead {
  font-family: $font-familyLogo;
  font-size: rem(32);
  font-weight: 500;

  @include MQ('md') {
    font-size: rem(26);
  }
}

.c-lower-heading__text {
  line-height: 1.7;
}
