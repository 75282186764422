@use '../Global/' as *;

.l-footer {
  margin-top: 80px;
  background-color: var(--mainColor);
  border-radius: 0 25vh 0 0;

  @include MQ(md) {
    margin-top: 60px;
    padding-bottom: 60px;
    border-radius: 0;
  }
}

.l-footer__inner {
  padding: 48px 66px 18px 20px;
  width: fit-content;
  margin-inline: auto;

  @include MQ(md) {
    padding: 30px 20px 16px;
  }
}

.l-footer__main {
  display: flex;
  column-gap: 82px;

  @include MQ(lg) {
    column-gap: 56px;
  }

  @include MQ(md) {
    justify-content: center;
  }
}

.l-footer__info {
  padding-top: 32px;
}

.l-footer__info-logo {
  display: block;
  width: 226px;
}

.l-footer__info-link {
  width: 226px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-familyLato;
  font-size: rem(24);
  font-weight: 400;
  padding: 8px 20px;
  margin-top: 28px;
  background-color: var(--accentColor);
  transition: background-color .3s;

  @include MQ(md) {
    margin-top: 48px;
  }

  &:hover {
    background-color: var(--lightYellow);
  }
}

.l-footer__info-lead {
  margin-top: 8px;
  font-family: $font-familyLogo;
  font-size: rem(18);
  font-weight: 500;
  color: var(--white);
  text-align: center;
}

.l-footer__sns {
  margin-top: 24px;

  @include MQ(md) {
    margin-top: 20px;
  }
}

.l-footer__nav {

  @include MQ(md) {
    display: none;
  }
}

.l-footer__list {
  display: flex;
  column-gap: 82px;

  @include MQ(lg) {
    column-gap: 56px;
  }

  @include MQ(xl) {
    row-gap: 24px;
    flex-wrap: wrap;
  }
}

.l-footer__item {
  flex-shrink: 0;
}

.l-footer__item--other {
  margin-left: 56px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 24px;

  @include MQ(xl) {
    margin-left: 0;
    flex-direction: row;
    column-gap: 196px;
  }
}

.l-footer__link {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  color: var(--white);
  transition: opacity .3s;

  &:hover {
    opacity: .7;
  }
}

.l-footer__child {
  margin-top: 8px;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
}

.l-footer__child-item {
  padding-left: 24px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 16px;
    height: 1px;
    background-color: var(--white);
    border-radius: 50%;
    transform: translateY(-50%);
  }
}

.l-footer__child-link {
  font-family: $font-familyLogo;
  font-size: rem(16);
  font-weight: 500;
  color: var(--white);
  transition: opacity .3s;

  &:hover {
    opacity: .7;
  }
}

.l-footer__copy {
  margin-top: 36px;
  font-size: rem(14);
  font-weight: 400;
  text-align: center;
  color: var(--white);

  @include MQ(md) {
    margin-top: 48px;
    font-size: rem(12);
  }
}
