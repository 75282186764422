@use '../Global/' as *;

.c-sns {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 53px;
}

.c-sns__item {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-sns__link {
  transition: opacity .3s;

  &:hover {
    opacity: .7;
  }
}