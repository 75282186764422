@use '../Global/' as *;

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-visually-hidden {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  margin: -1px;
  padding: 0;
  width: 1px;
  height: 1px;
  border: 0;
  white-space: nowrap;

  clip-path: inset(50%);
}

.u-bold {
  font-weight: bold;
}

.u-block {
  display: block;
}

.u-left {
  text-align: left;
}

.u-center {
  text-align: center;
}

.u-font-logo {
  font-family: $font-familyLogo;
}
