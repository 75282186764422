@use '../Global/' as *;

.p-top {
  overflow: hidden;
}

.p-top-mv {
  margin-top: 40px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100dvh - 85px);
  max-height: 643px;
  min-height: 560px;
  width: calc(100% - 88px);
  background-color: var(--mainColor);
  border-radius: 60px;
  box-shadow: 0px 5px 5px 0px rgba(165, 165, 165, 0.3);

  @include MQ(lg) {
    width: calc(100% - 16px);
  }

  @include MQ(md) {
    margin-top: 0;
    align-items: flex-start;
    padding-top: 120px;
    height: 684px;
    min-height: 684px;
    width: 100%;
    border-radius: 0 0 60px 60px;
    box-shadow: none;
  }
}

.p-top-mv__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 210px;
  padding-inline: 20px;
  margin-left: -114px;

  @include MQ(xl) {
    column-gap: 16vw;
    margin-left: -95px;
  }

  @include MQ(lg) {
    column-gap: 12vw;
  }

  @include MQ(md) {
    flex-direction: column-reverse;
    row-gap: 40px;
    margin-left: 0;
  }
}

.p-top-mv__main {
  display: flex;
  flex-direction: column-reverse;
  row-gap: 28px;

  @include MQ(md) {
    align-items: center;
    row-gap: 16px;
  }
}

.p-top-mv__title {
  font-family: $font-familyLogo;
  font-size: rem(25);
  font-weight: 500;
  color: var(--white);
  padding-left: 20px;

  @include MQ(xl) {
    font-size: rem(21);
  }

  @include MQ(lg) {
    padding-left: 0;
    font-size: rem(19);
  }

  @include MQ(md) {
    text-align: center;
  }

  @include MQ(sm) {
    font-size: rem(16);
  }
}

.p-top-mv__logo {
  width: 466px;
  padding-left: 20px;

  @include MQ(xl) {
    width: 408px;
  }

  @include MQ(lg) {
    padding-left: 0;
    width: 320px;
  }

  @include MQ(md) {
    width: 196px;
  }

  @include MQ(sm) {
    width: 196px;
  }
}

.p-top-mv__people {
  display: flex;
  justify-content: center;
  column-gap: 100px;

  @include MQ(xl) {
    column-gap: 72px;
  }
}

.p-top-mv__people-image {
  width: 166px;

  @include MQ(xl) {
    width: 140px;
  }
}

.p-top-mv__people-image--light {
  position: relative;
  z-index: 0;

  &::before {
    content: '';
    position: absolute;
    bottom: -56px;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
    width: 393px;
    height: 737px;
    background-image: url('../images/top/light.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    @include MQ(xl) {
      width: 330px;
      height: 620px;
    }
  }
}

.p-top-mv__head {
  display: flex;
  column-gap: 44px;
  margin-bottom: 20px;
  padding-block: 14px;
  position: relative;
  z-index: 1;

  @include MQ(xl) {
    margin-bottom: 16px;
    padding-block: 10px;
  }

  @include MQ(lg) {
    column-gap: 32px;
    margin-bottom: 8px;
  }

  @include MQ(md) {
    column-gap: 25px;
    padding: 12px 0 12px 16px;
    position: absolute;
    top: 142px;
    left: 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -45px;
    transform: translateY(-50%);
    width: 100vw;
    height: 100%;
    background-color: var(--accentColor);
    border-radius: 0 50vw 50vw 0;

    @include MQ(xl) {
      right: -32px;
    }

    @include MQ(md) {
      right: -25px;
    }
  }

  > * + * {

    &::before {
      content: '';
      width: 52px;
      height: 1.75px;
      background-color: var(--black);
      rotate: -60deg;
      position: absolute;
      top: 50%;
      left: -52px;

      @include MQ(xl) {
        width: 44px;
        left: -44px;
      }

      @include MQ(lg) {
        width: 30px;
        left: -30px;
      }

      @include MQ(md) {
        width: 30px;
        height: 1px;
        left: -30px;
      }
    }
  }
}

.p-top-mv__head__item {
  display: flex;
  align-items: center;
  column-gap: 8px;
  position: relative;
}

.p-top-mv__head__icon {

  @include MQ(lg) {
    height: 40px;
  }

  @include MQ(md) {
    height: 30px;
  }

  img {
    height: 100%;
    width: auto;
  }
}

.p-top-mv__head__text {
  font-family: $font-familyLogo;
  font-size: rem(48);
  font-weight: 500;
  letter-spacing: .2em;

  @include MQ(xl) {
    font-size: rem(40);
  }

  @include MQ(lg) {
    font-size: rem(32);
  }

  @include MQ(md) {
    font-size: rem(26);
  }
}

.p-top-mv__head__text--small {
  font-size: rem(38);
  margin-left: 10px;

  @include MQ(xl) {
    font-size: rem(32);
  }

  @include MQ(lg) {
    font-size: rem(26);
    margin-left: 6px;
  }

  @include MQ(md) {
    font-size: rem(20);
    margin-left: 5px;
  }
}


.p-top-about {
  margin-top: 80px;

  @include MQ(md) {
    margin-top: 64px;
  }
}

.p-top-about__feature {
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 48px;

  @include MQ(md) {
    margin-top: 20px;
    flex-direction: column;
    row-gap: 10px;
    align-items: flex-start;
  }
}

.p-top-about__feature-body {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ(md) {
    width: 100%;
    align-items: center;
  }
}

.p-top-about__feature-title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;

  @include MQ(md) {
    font-size: rem(22);
    text-align: center;
  }
}

.p-top-about__feature-text {
  line-height: 1.7;

  @include MQ(md) {
    font-size: rem(14);
  }
}

.p-top-about__feature-image {
  flex-shrink: 0;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--u-color-100);
  border-radius: 50%;

  @include MQ(lg) {
    width: 320px;
    height: 320px;
  }

  @include MQ(md) {
    margin-left: auto;
    width: 238px;
    height: 238px;
  }

  img {
    width: 132px;

    @include MQ(lg) {
      width: 106px;
    }
  }
}

.p-top-about__mission {
  margin-top: 112px;

  @include MQ(md) {
    margin-top: 60px;
  }
}

.p-top-about__mission-heading {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  column-gap: 46px;

  @include MQ(lg) {
    flex-direction: column-reverse;
    row-gap: 16px;
  }

  @include MQ(md) {
    align-items: center;
    row-gap: 16px;
  }
}

.p-top-about__mission-title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
  line-height: 1.6;
  
  --fadeTitle-position: 100%;

  @include MQ(md) {
    font-size: rem(22);
    text-align: center;
  }
}

.p-top-about__mission-title--border {
  --bg-sizeX: 0;
  background-size: var(--bg-sizeX) 100%;
  background-image: linear-gradient(transparent calc(100% - 30px), var(--u-color-100)  calc(100% - 30px), var(--u-color-100)  calc(100% - 10px), transparent 0%);
  display: inline;
  transition: background-size .5s ease-in-out;

  @include MQ(md) {
    background-image: linear-gradient(transparent calc(100% - 22px), var(--u-color-100)  calc(100% - 22px), var(--u-color-100)  calc(100% - 10px), transparent 0%);
  }

  > br {
    display: none;

    @include MQ(sm) {
      display: block;
    }
  }

  > span {
    display: inline-block;
    overflow: hidden;

    > span {
      display: inline-block;
      transform: translateY( var(--fadeTitle-position) );
    }
  }
}

.p-top-about__mission-label {
  flex-shrink: 0;
  font-family: $font-familyLato;
  font-size: rem(40);

  @include MQ(md) {
    font-size: rem(32);
  }
}

.p-top-about__mission-list {
  max-width: 864px;
  margin-top: 64px;
  margin-inline: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 96px;
  row-gap: 20px;

  @include MQ(lg) {
    column-gap: 28px;
  }

  @include MQ(md) {
    margin-top: 42px;
  }
}

.p-top-about__mission-item {
  flex-shrink: 0;
  width: 224px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 46px;

  @include MQ(md) {
    width: 180px;
    row-gap: 15px;
  }
}

.p-top-about__mission-speaking {
  font-family: $font-familyLogo;
  font-size: rem(20);
  font-weight: 500;
  width: max-content;
  padding-bottom: 30px;
  position: relative;

  @include MQ(md) {
    font-size: rem(16);
    text-align: center;
    padding-bottom: 25px;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 226px;
    height: 53px;
    background-image: url('../images/top/bubble.svg');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom center;

    @include MQ(md) {
      width: 180px;
    }
  }
}

.p-top-about__mission-image {
  width: 154px;
}

.p-top-about__bottom {
  margin-top: 56px;

  @include MQ(md) {
    margin-top: 40px;
  }
}

.p-top-about__bottom-heading {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;

  @include MQ(md) {
    font-size: rem(18);
  }
}

.p-top-about__bottom-heading--border {
  background-image: linear-gradient(transparent calc(100% - 12px), var(--u-color-100)  0%);
  display: inline;
}

.p-top-about__bottom-text {
  margin-top: 38px;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;

  @include MQ(md) {
    margin-top: 30px;
    font-size: rem(18);
  }
}

.p-top-about__bottom-content {
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 14px;

  @include MQ(md) {
    margin-top: 40px;
    row-gap: 24px;
  }
}

.p-top-about__bottom-lead {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;
  overflow: hidden;
  
  --fadeTitle-position: 100%;

  @include MQ(md) {
    font-size: rem(18);
  }

  > span {
    display: inline;

    > span {
      display: inline-block;
      transform: translateY( var(--fadeTitle-position) );
    }
  }
}

.p-top-about__bottom-strong {
  font-family: $font-familyLato;
  font-size: rem(40);
  text-align: center;
  overflow: hidden;
  
  --fadeTitle-position: 100%;

  @include MQ(md) {
    font-size: rem(32);
  }

  > span {
    --bg-sizeX: 0;
    background-size: var(--bg-sizeX) 100%;
    background-image: linear-gradient(transparent calc(100% - 19px), var(--u-color-100)  calc(100% - 19px), var(--u-color-100)  calc(100% - 7px), transparent 0%);
    display: inline;
    transition: background-size .5s ease-in-out;

    @include MQ(md) {
      font-size: rem(32);
      background-image: linear-gradient(transparent calc(100% - 16px), var(--u-color-100)  calc(100% - 16px), var(--u-color-100)  calc(100% - 4px), transparent 0%);
    }

    > span {
      display: inline-block;
      transform: translateY( var(--fadeTitle-position) );
    }
  }
}

.p-top-promise {
  margin-top: 305px;
  background-color: var(--u-color-100);

  @include MQ(md) {
    margin-top: 208px;
  }
}

.p-top-promise__inner {
  position: relative;
  padding-block: 88px 100px;

  @include MQ(md) {
    padding-block: 112px 64px;
  }

  &::before {
    content: '';
    position: absolute;
    top: -160px;
    left: 50%;
    transform: translateX(-50%);
    width: 571px;
    height: 571px;
    background-color: var(--u-color-100);
    border-radius: 50%;

    @include MQ(md) {
      top: -120px;
      width: 240px;
      height: 240px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: -240px;
    left: 50%;
    transform: translateX(-50%);
    width: 104px;
    height: 60px;
    background-image: url('../images/top/promise-deco.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include MQ(md) {
      top: -170px;
      width: 73px;
      height: 42px;
    }
  }
}

.p-top-promise__deco {
  width: 132px;
  position: absolute;
  top: -150px;
  left: 50%;
  transform: translateX(-50%);
  
  @include MQ(md) {
    top: -103px;
    width: 115.2px;
  }
}

.p-top-promise__lead {
  margin-top: 20px;
  font-size: rem(20);
  text-align: center;

  @include MQ(md) {
    font-size: rem(18);
  }
}

.p-top-promise__list {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ(md) {
    margin-top: 20px;
  }
}

.p-top-promise__item {
  display: flex;
  align-items: center;
  column-gap: 48px;
  background-color: var(--white);
  padding: 20px 80px;
  border-radius: 10px;

  @include MQ(lg) {
    padding: 20px 32px;
    column-gap: 24px;
  }

  @include MQ(md) {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
    padding: 24px;
  }
}

.p-top-promise__title {
  flex-shrink: 0;
  width: 330px;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;

  @include MQ(lg) {
    width: 288px;
    font-size: rem(22);
  }

  @include MQ(md) {
    width: 100%;
    font-size: rem(20);
  }
}

.p-top-promise__detail {
  font-size: rem(18);

  @include MQ(md) {
    font-size: rem(16);
  }
}

.p-top-price {
  margin-top: 80px;

  @include MQ(md) {
    margin-top: 64px;
  }
}

.p-top-price__lead {
  margin-top: 20px;
  font-size: rem(20);
  line-height: 1.7;
  text-align: center;

  @include MQ(md) {
    font-size: rem(18);
  }
}

.p-top-price__content {
  margin-top: 40px;
  padding: 32px 56px 72px 72px;
  background-color: var(--u-color-100);
  border-radius: 10px;

  @include MQ(lg) {
    padding: 32px 20px 72px 20px;
  }

  @include MQ(md) {
    padding: 40px 10px;
  }
}

.p-top-price__inner {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  column-gap: 84px;

  @include MQ(md) {
    flex-direction: column;
    align-items: center;
    row-gap: 52px;
  }
}

.p-top-price__body {
  width: 472px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ(md) {
    width: 100%;
    max-width: 472px;
    row-gap: 18px;
  }
}

.p-top-price__title {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  padding-left: 42px;
  position: relative;

  @include MQ(md) {
    font-size: rem(18);
    padding-left: 37px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    background-color: var(--accentColor);
  }
}

.p-top-price__list {
  display: flex;
  justify-content: space-between;

  @include MQ(md) {
    margin-top: 8px;
  }
}

.p-top-price__item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 16px;

  @include MQ(md) {
    row-gap: 0;
  }
}

.p-top-price__item-title {
  font-size: rem(20);
  line-height: 1.7;
  text-align: center;

  @include MQ(md) {
    font-size: rem(16);
  }
}

.p-top-price__item-icon {
  height: 53px;
  max-width: 61px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: auto;
    height: 100%;
  }

  @include MQ(md) {
    margin-top: 18px;
    height: 42px;
    max-width: 52px;
  }
}

.p-top-price__item-price {
  display: flex;
  align-items: baseline;
  column-gap: 3px;
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(20);
  position: relative;

  @include MQ(md) {
    margin-top: 8px;
    font-size: rem(18);
  }
}

.p-top-price__item-price--unit {
  font-family: $font-family;
  font-size: rem(14);
  font-weight: 500;

  @include MQ(md) {
    font-size: rem(12);
  }
}

.p-top-price__item-price--caution {
  font-family: $font-family;
  font-size: rem(12);
  position: absolute;
  right: -26px;

  @include MQ(md) {
    font-size: rem(8);
    right: -20px;
  }
}

.p-top-price__total {
  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 2px;
  font-size: rem(20);
  background-color: var(--white);

  @include MQ(md) {
    column-gap: 8px;
    font-size: rem(18);
  }
}

.p-top-price__total--num {
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(26);

  @include MQ(md) {
    font-size: rem(22);
  }
}

.p-top-price__total--unit {
  font-family: $font-family;
  font-size: rem(16);
  font-weight: 500;

  @include MQ(md) {
    font-size: rem(12);
  }
}

.p-top-price__main {
  flex-shrink: 0;
  width: 300px;

  @include MQ(lg) {
    width: 268px;
  }

  @include MQ(md) {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column-reverse;
  }
}

.p-top-price__bubble {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(21);
  background-color: var(--white);
  padding-block: 8px 4px;
  position: relative;

  @include MQ(md) {
    margin-top: 24px;
    font-size: rem(18);
  }

  &::after {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 30px;
    width: 17px;
    height: 17px;
    background-color: var(--white);
    clip-path: polygon(50% 100%, 0 0, 100% 0);

    @include MQ(md) {
      bottom: unset;
      top: -12px;
      transform: scaleY(-1);
    }
  }
}

.p-top-price__fraction {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 6px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 31px;
    height: 1px;
    background-color: var(--black);
  }
}

.p-top-price__fraction--num {
  font-size: rem(26);
  line-height: 1;
}

.p-top-price__main-list {
  margin-top: 48px;
  padding-left: 22px;
  display: flex;
  flex-direction: column;
  row-gap: 56px;
  position: relative;

  @include MQ(lg) {
    padding-left: 2px;
  }

  @include MQ(md) {
    margin-top: 0;
    padding-left: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: -72px;
    transform: translateY(-50%);
    width: 59px;
    height: 143px;
    background-image: url('../images/top/price-arrow.svg');
    background-repeat: no-repeat;
    background-size: contain;

    @include MQ(md) {
      top: -48px;
      left: 50%;
      transform: translateX(-50%);
      width: 198px;
      height: 39px;
      background-image: url('../images/top/price-arrow_sp.svg');
    }
  }
}

.p-top-price__main-item {
  display: flex;
  column-gap: 30px;

  @include MQ(md) {
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
  }
}

.p-top-price__main-logo {
  width: 132px;

  @include MQ(md) {
    width: 115px;
  }
}

.p-top-price__main-title {
  width: 132px;
  font-size: rem(20);
  font-weight: 700;
  text-align: center;

  @include MQ(md) {
    width: 105px;
    font-size: rem(18);
  }
}

.p-top-price__main-content {

}

.p-top-price__main-label {
  font-size: rem(14);
  font-weight: 700;
  line-height: 1.7;

  @include MQ(md) {
    text-align: center;
  }
}

.p-top-price__main-price {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  column-gap: 2px;
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(28);
  position: relative;

  @include MQ(md) {
    font-size: rem(24);
  }
}

.p-top-price__main-price--unit {
  font-family: $font-family;
  font-size: rem(18);
  font-weight: 500;

  @include MQ(md) {
    font-size: rem(14);
  }
}

.p-top-price__main-price--caution {
  font-family: $font-family;
  font-size: rem(12);
  position: absolute;
  right: -2.5px;
  bottom: 28px;

  @include MQ(md) {
    right: -13.5px;
    bottom: 22px;
  }
}

.p-top-price__caution {
  width: 100%;
  max-width: 914px;
  margin-top: 10px;
  margin-left: auto;
}

.p-top-price__caution-item {
  display: inline;
  font-size: rem(14);
  line-height: 1.7;

  @include MQ(md) {
    font-size: rem(12);
  }
}

.p-top-flow {
  margin-top: 80px;
  background-color: var(--u-color-100);

  @include MQ(md) {
    margin-top: 64px;
  }
}

.p-top--flow__inner {
  width: 100%;
  max-width: 1160px;
  padding: 96px 20px 82px;
  margin: 0 auto;
  display: flex;
  column-gap: 96px;
  position: relative;

  @include MQ(lg) {
    column-gap: 40px;
  }

  @include MQ(md) {
    padding: 64px 20px 120px;
    column-gap: 24px;
  }
}

.p-top--flow__heading {
  flex-shrink: 0;
  display: flex;
  flex-direction: row-reverse;
  column-gap: 16px;
  padding-right: 28px;
  position: relative;

  @include MQ(md) {
    column-gap: 6px;
    padding-right: 0;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 2px;
    height: 100px;
    background-color: var(--mainColor);

    @include MQ(md) {
      display: none;
    }
  }
}

.p-top--flow__title {
  font-family: $font-familyLogo;
  font-size: rem(32);
  font-weight: 500;
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  line-height: 1;

  @include MQ(md) {
    font-size: rem(26);
  }
}

.p-top--flow__label {
  font-family: $font-familyLato;
  font-size: rem(20);
  font-weight: 500;
  color: var(--u-color-400);
  -ms-writing-mode: tb-rl;
  writing-mode: vertical-rl;
  text-orientation: upright;
  line-height: 1;
}

.p-top--flow__body {
  width: 100%;
  height: 532px;
  background-color: var(--white);
  box-shadow: 0px 5px 5px 0px #A5A5A54D;
  border: solid var(--mainColor);
  border-width: 0 0 2px 2px;
  position: relative;
  margin-right: 96px !important;
  padding-inline: 24px !important;

  @include MQ(md) {
    height: 418px;
    margin-right: 0 !important;
    padding-inline: 10px !important;
  }
}

.p-top--flow__list {
}

.p-top--flow__item {
  padding-top: 30px;
  position: relative;
}

.p-top--flow__lead {
  font-family: $font-familyLogo;
  font-size: rem(32);
  font-weight: 500;
  text-align: center;

  @include MQ(md) {
    font-size: rem(24);
  }
}

.p-top--flow__text {
  margin-top: 16px;
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
  text-align: center;

  @include MQ(lg) {
    font-size: rem(24);
  }

  @include MQ(md) {
    font-size: rem(18);
  }
}

.p-top--flow__image {
  max-width: 215px;
  margin-top: 16px;
  margin-inline: auto;

  @include MQ(md) {
    max-width: 139px;
  }
}

.p-top--flow__bottom {
  margin-top: 12px;
  font-size: rem(20);
  font-weight: 500;
  text-align: center;

  @include MQ(md) {
    margin-top: 20px;
    font-size: rem(16);
  }
}

.p-top--flow__bubble {
  position: absolute;
  top: 55%;
  right: 10%;
  transform: translateY(-50%);

  @include MQ(xl) {
    right: 5%;
  }

  @include MQ(lg) {
    right: 14vw;
    top: 75%;
  }

  @include MQ(sm) {
    right: 0;
  }
}

.p-top--flow__navigation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 22px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;

  @include MQ(md) {
    flex-direction: row;
    column-gap: 22px;
    right: 20px;
    top: unset;
    bottom: 22px;
  }

  .swiper-button-next {
    flex-shrink: 0;
    position: static;
    width: 60px;
    height: 60px;
    background-color: var(--white);
    border: 2px solid var(--mainColor);
    border-radius: 50%;
    margin-top: 0;

    @include MQ(md) {
      width: 55px;
      height: 55px;
    }

    img {
      width: 29px;
      transform: scaleY(-1) translateY(-2px);

      @include MQ(md) {
        width: 25px;
        transform: rotate(90deg) translateY(-2px);
      }
    }

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    flex-shrink: 0;
    position: static;
    width: 60px;
    height: 60px;
    background-color: var(--white);
    border: 2px solid var(--mainColor);
    border-radius: 50%;
    margin-top: 0;

    @include MQ(md) {
      width: 55px;
      height: 55px;
    }

    img {
      width: 29px;
      transform: translateY(-1px);

      @include MQ(md) {
        width: 25px;
        transform: rotate(-90deg) translateY(-2px);
      }
    }

    &::after {
      display: none;
    }
  }
}

.p-top-insta {
  margin-top: 158px;

  @include MQ(md) {
    margin-top: 64px;
  }
}

.p-top-insta__list {
  margin-top: 24px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 32px;
  row-gap: 32px;

  @include MQ(md) {
    margin-top: 36px;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 24px;
    row-gap: 24px;
  }

  @include MQ(sm) {
    grid-template-columns: repeat(1, 1fr);
  }
}

.p-top-insta__item {

  &:nth-child(n + 5) {

    @include MQ(md) {
      display: none;
    }
  }

  &:nth-child(n + 4) {

    @include MQ(sm) {
      display: none;
    }
  }
}

.p-top-banner {
  margin-top: 48px;

  @include MQ(md) {
    margin-top: 24px;
  }
}

.p-top-banner__inner {
  max-width: 632px;
  margin-inline: auto;
  padding-inline: 16px;
}

.p-top-banner__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.p-top-banner__image {
  margin-bottom: 16px;
  transition: opacity .3s ease-in-out;

  &:hover {
    opacity: .8;
  }
}

.p-top-banner__text {
  font-size: rem(15);
  text-align: center;

  a {
    text-decoration: underline;
    transition: opacity .3s ease-in-out;

    &:hover {
      opacity: .8;
    }
  }
}

