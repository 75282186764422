@use '../Global/' as *;

.p-price-system {
  margin-top: 132px;

  @include MQ('md') {
    margin-top: 40px;
  }
}

.p-price-system__inner {
  padding: 36px 32px 50px 12px;
  background-color: var(--mainColor);
  border-radius: 10px;

  @include MQ('md') {
    padding: 42px 24px 32px 10px;
  }
}

.p-price-system__title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
  text-align: center;
  color: var(--white);

  @include MQ('md') {
    font-size: rem(22);
  }
}

.p-price-system__text {
  margin-top: 12px;
  font-size: rem(18);
  line-height: 1.7;
  text-align: center;
  color: var(--white);

  @include MQ('md') {
    margin-top: 18px;
    font-size: rem(16);
  }
}

.p-price-system__image {
  width: 100%;
  max-width: 860px;
  margin-right: auto;

  @include MQ('md') {
    max-width: 440px;
    margin-top: 12px;
    margin-left: auto;
  }
}

.p-price-menu {
  margin-top: 123px;

  @include MQ('md') {
    margin-top: 88px;
  }
}

.p-price-menu__lead {
  margin-top: 40px;
  font-size: rem(20);
  line-height: 1.7;
  text-align: center;

  @include MQ('md') {
    margin-top: 24px;
    font-size: rem(18);
  }
}

.p-price-menu__list {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(auto-fit, 256px);
  justify-content: center;
  column-gap: 30px;
  row-gap: 30px;

  @include MQ('md') {
    margin-top: 24px;
    grid-template-columns: repeat(auto-fit, 320px);
    column-gap: 20px;
    row-gap: 10px;
  }

  @include MQ('sm') {
    grid-template-columns: 1fr;
  }
}

.p-price-menu__item {
}

.p-price-menu__link {
  display: block;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;
  color: var(--mainColor);
  padding-block: 11px;
  border: 2px solid var(--mainColor);
  border-radius: 50vh;
  transition: color .3s, border-color .3s;

  @include MQ('md') {
    font-size: rem(18);
    padding-block: 4.5px;
  }

  &:hover {
    color: var(--u-color-300);
    border-color: var(--u-color-300);
  }
}

.p-price-content {
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  row-gap: 80px;

  @include MQ('md') {
    margin-top: 56px;
    row-gap: 60px;
  }
}

.p-price-content__inner {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.p-price-content__header {
  display: flex;
  column-gap: 32px;

  @include MQ('md') {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 36px;
  }
}

.p-price-content__heading {
  flex-shrink: 0;
  width: 256px;
  padding-left: 36px;
  position: relative;

  @include MQ('md') {
    width: 100%;
    padding-bottom: 24px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 4px;
    transform: rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: var(--accentColor);
  }

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 1px;
    height: 100%;
    background-color: var(--black);

    @include MQ('md') {
      inset: unset;
      bottom: 0;
      transform: none;
      width: 50px;
      height: 1px;
    }
  }
}

.p-price-content__head {
  max-width: 172px;
  font-family: $font-familyLogo;
  font-size: rem(18);
  font-weight: 500;
}

.p-price-content__title {
  max-width: 172px;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  position: relative;

  @include MQ('md') {
    max-width: 100%;
  }
}

.p-price-content__lead {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
}

.p-price-content__overview {
  font-size: rem(18);
  line-height: 1.7;

  @include MQ('md') {
    font-size: rem(16);
  }
}

.p-price-content__sideeffects {
  font-size: rem(14);
  line-height: 1.7;

  @include MQ('md') {
    font-size: rem(12);
  }
}

.p-price-content__list {
  width: 100%;
  max-width: 1025px;
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  row-gap: 40px;

  @include MQ('md') {
    row-gap: 30px;
  }
}

.p-price-content__item {
  display: flex;
  column-gap: 30px;

  @include MQ('md') {
    flex-direction: column;
    row-gap: 10px;
  }
}

.p-price-content__main {
  width: 352px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
  padding: 16px;
  background-color: var(--u-color-300);
  border-radius: 10px;

  @include MQ('xl') {
    width: 300px;
  }

  @include MQ('md') {
    width: 100%;
    align-items: flex-start;
    row-gap: 0;
    padding: 6px 18px;
    border-radius: 5px;
  }
}

.p-price-content__name {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;
  color: var(--white);

  @include MQ('md') {
    text-align: left;
  }

  > br {

    @include MQ('md') {
      display: none;
    }
  }
}

.p-price-content__subttl {
  font-size: rem(16);
  line-height: 1.7;
  text-align: center;
  color: var(--white);

  @include MQ('md') {
    font-size: rem(14);
    text-align: left;
  }

  > br {

    @include MQ('md') {
      display: none;
    }
  }
}

.p-price-content__wrapper {
  width: calc(100% - 352px - 30px);
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ('xl') {
    width: calc(100% - 300px - 30px);
  }

  @include MQ('md') {
    width: 100%;
    row-gap: 10px;
  }
}

.p-price-content__container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  padding: 24px 40px;
  background-color: var(--u-color-100);
  border-radius: 10px;

  @include MQ('lg') {
    padding: 24px 40px;
  }

  @include MQ('md') {
    padding: 20px;
  }

  &:not(:has(.p-price-content__amount)) {
    row-gap: 10px;
  }
}

.p-price-content__label {
  font-family: $font-familyLogo;
  font-size: rem(20);
  font-weight: 500;
}

.p-price-content__table {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.p-price-content__row {
  display: flex;
  column-gap: 56px;
  row-gap: 8px;

  @include MQ('lg') {
    column-gap: 24px;
    justify-content: space-between;
  }
}

.p-price-content__amount {
  min-width: 66px;
  display: flex;
  align-items: baseline;
  justify-content: flex-end;
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(30);
  font-weight: 300;
  letter-spacing: 0;

  @include MQ('md') {
    justify-content: flex-start;
    font-size: rem(30);
  }
}

.p-price-content__amount--unit {
  font-family: $font-family;
  font-size: rem(18);
  font-weight: 400;

  @include MQ('md') {
    font-size: rem(20);
  }
}

.p-price-content__price {
  display: flex;
  align-items: baseline;
  column-gap: 2px;
  font-family: $font-familyDin;
  font-style: normal;
  font-size: rem(30);
  font-weight: 300;
  letter-spacing: 0;
  text-align: right;

  @include MQ('md') {
    font-size: rem(30);
  }
}

.p-price-content__price--unit {
  font-family: $font-family;
  font-size: rem(18);
  font-weight: 400;

  @include MQ('md') {
    font-size: rem(20);
  }
}

.p-price-content__set {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 24px;
  padding: 24px 108px;
  background-color: var(--u-color-100);
  border-radius: 10px;

  @include MQ('lg') {
    padding: 24px 40px;
  }

  @include MQ('md') {
    padding: 20px 20vw;
  }

  @include MQ('sm') {
    padding: 20px;
  }
}

.p-price-content__set-header {
  margin-top: 40px;
}

.p-price-content__set-heading {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  row-gap: 4px;
}

.p-price-content__set-title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
}

.price-content__set-lead {
  width: fit-content;
  margin-inline: auto;
  font-size: rem(20);
  line-height: 1.7;
  padding-inline: 38px;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 28.6px;
    height: 1px;
    background-color: var(--black);
    position: absolute;
  }

  &::before {
    position: absolute;
    top: 50%;
    left: -8px;
    transform: translateY(-50%) rotate(62.95deg);
  }

  &::after {
    position: absolute;
    top: 50%;
    right: -8px;
    transform: translateY(-50%) rotate(117.05deg);
  }
}

.price-content__set-lead--strong {
  color: var(--mainColor);
}

.p-price-content__set-list {
  display: flex;
  justify-content: space-between;

  &:not(:has(.p-price-content__set-item + .p-price-content__set-item)) {
    justify-content: center;
  }
}

.p-price-content__set-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
}

.p-price-content__detail {
  display: flex;
  column-gap: 24px;

  @include MQ('lg') {
    flex-direction: column;
    row-gap: 16px;
  }
}

.p-price-content__box {
  width: 208px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
}

.p-price-content__normal {
  width: 82px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(12);
  line-height: 1.7;
  color: var(--u-color-300);
  background-color: var(--white);
  border: 1px solid var(--u-color-300);
}

.p-price-content__monitor {
  width: 82px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: rem(12);
  line-height: 1.7;
  color: var(--white);
  background-color: var(--u-color-300);
}

.p-price-content__wrapper--set {
  background-color: var(--u-color-100);
  border-radius: 10px;
  row-gap: 0;

  .p-price-content__container + .p-price-content__container {
    padding-top: 0;
  }
  
  .p-price-content__detail {

    @include MQ('lg') {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @include MQ('md') {
      justify-content: flex-end;
    }
  }
}

.p-price-content__wrapper--option {
  .p-price-content__row {
    column-gap: 24px;
  }

  .p-price-content__amount {
    width: 52px;
    justify-content: flex-start;
  }

  .p-price-content__normal {
    display: none;
  }

  .p-price-content__box {
    width: auto;
  }
}
