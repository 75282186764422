@use '../Global/' as *;

.l-header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
  background-color: var(--mainColor);
  border-bottom: 1px solid var(--white);
}

.l-header__inner {
  max-width: 1920px;
  margin-inline: auto;
  padding: 16px 70px 16px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @include MQ(xl) {
    padding: 16px 20px;
  }

  @include MQ(md) {
    padding: 20px 20px;
  }
}

.l-header__logo {
  width: 120px;
  flex-shrink: 0;

  @include MQ(md) {
    width: 92px;
  }
}

.l-header__nav {

  @include MQ(md) {
    position: fixed;
    top: 0;
    right: -100%;
    width: 100%;
    height: 100vh;
    padding-top: 120px;
    padding-bottom: 64px;
    background-color: var(--mainColor);
    overflow-y: auto;
    transition: right .3s;
  }
}

.l-header__nav-list {
  display: flex;
  align-items: center;
  column-gap: 16px;

  @include MQ(md) {
    margin-top: 40px;
    flex-direction: column;
    justify-content: center;
    row-gap: 24px;
  }
}

.l-header__nav-item {
  min-width: 96px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:has(.l-header__nav-box) {

    @include MQ(md) {
      display: none;
    }
  }
}

.l-header__nav-link {
  font-family: $font-familyLato;
  font-size: rem(24);
  font-weight: 400;
  color: var(--white);
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--white);
    transform: scaleX(0);
    transform-origin: center;
    transition: transform .3s;
  }

  &:hover::before {
    transform: scaleX(1);
  }
}

.l-header__nav-box {
  font-family: $font-familyLato;
  font-size: rem(24);
  font-weight: 400;
  padding: 8px 20px;
  background-color: var(--accentColor);
  transition: background-color .3s;

  &:hover {
    background-color: var(--lightYellow);
  }
}

.l-header__info {
  display: none;

  @include MQ(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: fit-content;
    margin-inline: auto;
  }
}

.l-header__info-logo {
  display: block;
  width: 226px;
}

.l-header__info-link {
  width: 226px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
  font-family: $font-familyLato;
  font-size: rem(24);
  font-weight: 400;
  padding: 8px 20px;
  background-color: var(--accentColor);
}

.l-header__info-lead {
  margin-top: 8px;
  font-family: $font-familyLogo;
  font-size: rem(18);
  font-weight: 500;
  color: var(--white);
  text-align: center;
}

.l-header__sns {
  display: none;

  @include MQ(md) {
    display: block;
    width: fit-content;
    margin-top: 60px;
    margin-inline: auto;
  }
}

.l-header__bubble {
  margin-top: 16px;
  max-width: 226px;
  font-size: rem(12);
  font-weight: 700;
  line-height: 2.16667;
  text-align: center;
  color: var(--mainColor);
  background-color: var(--white);
  border-radius: 50vh;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -5px;
    right: 14px;
    width: 11.312px;
    height: 5.656px;
    background-color: var(--white);
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
  }
}

.l-header__hamb {
  display: none;

  @include MQ(md) {
    display: block;
  }
}

.l-header__hamb-border {
  width: 50px;
  height: 42px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 10px;

  &::before,
  &::after {
    content: '';
    width: 100%;
    height: 2px;
    background-color: var(--accentColor);
    transition: transform .3s;
  }
}

.l-header__hamb-label {
  font-family: $font-familyLato;
  font-size: rem(14);
  font-weight: 700;
  color: var(--accentColor);
}

[data-hamb="open"] {
  overflow: hidden;

  .l-header__nav {

    @include MQ(md) {
      right: 0;
    }
  }

  .l-header__hamb-border {

    &::before {
      transform: translateY(20px) rotate(-45deg);
    }

    &::after {
      transform: translateY(-20px) rotate(45deg);
    }
  }

  .l-header__hamb-label {
    opacity: 0;
    transition: opacity .3s;
  }
}
