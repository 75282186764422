@use '../Global/' as *;

.js-fadeIn {
  opacity: 0;
}

.js-fadeUp {
  opacity: 0;
  transform: translateY(50px);
}
