@use '../Global/' as *;

html,
body {
  margin: 0;
  padding: 0;
  width: 100%;
}

* {
  box-sizing: border-box;

  &::before,
  &::after {
    box-sizing: border-box;
  }
}

:root {
  --header-height: 85px;

  @include MQ(md) {
    --header-height: 82px;
  }
}

html {
  font-size: $basefontsize + px;
  font-family: $font-family;
  font-style: normal;
  @include font-default;
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--header-height) + 10px);
}

body {
  background-color: var(--white);
  color: var(--black);
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100dvh;
  overflow-x: hidden;
}

main {
  overflow: hidden;
  flex: 1;
  margin-top: var(--header-height);
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

p {
  margin: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

picture {
  display: flex;
}

figure {
  margin: 0;
}

img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
