@use '../Global/' as *;

.p-privacy {
  margin-top: 80px;

  @include MQ(md) {
    margin-top: 40px;
  }
}

.p-privacy__inner {
  display: flex;
  flex-direction: column;
  row-gap: 40px;
}

.p-privacy__content {
}

.p-privacy__title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;

  @include MQ(md) {
    font-size: rem(22);
  }
}

.p-privacy__body {
  margin-top: 24px;
  padding-left: 40px;

  @include MQ(md) {
    padding-left: 32px;
  }

  * + * {
    --mt-apacer: 0;
    margin-top: var(--mt-apacer);
  }
}

.p-privacy--spacer {
  padding-left: 56px;

  @include MQ(md) {
    padding-left: 20px;
  }
}

.p-privacy__lead {
  --mt-apacer: 24px;
  font-size: rem(20);
  line-height: 1.7;

  @include MQ(md) {
    font-size: rem(18);
  }
}

.p-privacy__text {
  --mt-apacer: 16px;
  font-size: rem(16);
  line-height: 1.7;
}

.p-privacy__list {
  --mt-apacer: 16px;
  list-style: disc;

  @include MQ(md) {
    padding-left: 36px;
  }
}

.p-privacy__list-item {

  &::marker {
    font-size: 80%;
  }
}

.p-privacy__link {
  --mt-apacer: 16px;
  display: block;
  width: fit-content;
  font-size: rem(16);
  line-height: 1.7;
  border-bottom: 1px solid var(--black);
}
