@use '../Global/' as *;

.p-qa {
  margin-top: 80px;

  @include MQ('md') {
    margin-top: 40px;
  }
}

.p-qa-nav {
  display: grid;
  grid-template-columns: repeat(auto-fit, 256px);
  justify-content: center;
  column-gap: 30px;
  row-gap: 30px;

  @include MQ('md') {
    margin-top: 24px;
    grid-template-columns: repeat(auto-fit, 320px);
    column-gap: 20px;
    row-gap: 10px;
  }

  @include MQ('sm') {
    grid-template-columns: 1fr;
  }
}

.p-qa-nav__item {
}

.p-qa-nav__link {
  display: block;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  text-align: center;
  color: var(--mainColor);
  padding-block: 11px;
  border: 2px solid var(--mainColor);
  border-radius: 50vh;
  transition: color .3s, border-color .3s;

  @include MQ('md') {
    font-size: rem(18);
    padding-block: 4.5px;
  }

  &:hover {
    color: var(--u-color-300);
    border-color: var(--u-color-300);
  }
}

.p-qa__main {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  row-gap: 80px;

  @include MQ('md') {
    margin-top: 40px;
    row-gap: 40px;
  }
}

.p-qa__content {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ('md') {
    row-gap: 16px;
  }
}

.p-qa__title {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  padding-left: 46px;
  position: relative;

  @include MQ('md') {
    padding-left: 38px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 4px;
    transform: rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: var(--accentColor);
  }
}

.p-qa__list {
  display: flex;
  flex-direction: column;
  row-gap: 24px;

  @include MQ('md') {
    row-gap: 20px;
  }
}

.p-qa__item {
  padding: 20px 40px;
  background-color: var(--u-color-100);
  border-radius: 16px;
  overflow: hidden;

  @include MQ('md') {
    padding: 20px 20px;
  }

  &[open] {
    
    .p-qa__question {
      &::after {
        opacity: 0;
      }
    }
  }
}

.p-qa__question {
  list-style: none;
  cursor: pointer;
  position: relative;

  &::-webkit-details-marker {
    display: none;
  }

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 26px;
    height: 2px;
    background-color: var(--black);

    @include MQ('md') {
      width: 14px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    width: 2px;
    height: 26px;
    background-color: var(--black);
    transition: opacity .2s;

    @include MQ('md') {
      right: 6px;
      height: 14px;
    }
  }
}

.p-qa__name {
  display: flex;
  column-gap: 40px;
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;
  padding-right: 36px;
  position: relative;

  @include MQ('md') {
    column-gap: 12px;
    font-size: rem(18);
    padding-right: 34px;
  }

  &::before {
    content: 'Q';
    font-family: $font-familyLato;
    font-size: rem(40);
    color: var(--blue);

    @include MQ('md') {
      font-size: rem(32);
    }
  }
}

.p-qa__name--wrapper {
  padding-block: 12px;

  @include MQ('md') {
    padding-block: 10px 0;
  }
}

.p-qa__answer {
  display: flex;
  column-gap: 50px;

  @include MQ('md') {
    column-gap: 16px;
  }

  &::before {
    content: 'A';
    font-family: $font-familyLato;
    font-size: rem(40);
    color: var(--pink);
    margin-top: 14px;

    @include MQ('md') {
      font-size: rem(32);
      margin-top: 14px;
    }
  }
}

.p-qa__text {
  font-size: rem(18);
  line-height: 1.7;
  margin-top: 16px;
  padding-top: 8px;
  padding-right: 36px;
  position: relative;

  @include MQ('md') {
    font-size: rem(16);
    margin-top: 12px;
    padding-right: 0;
  }
}

.p-qa__link {
  border-bottom: 1px solid var(--black);
}

.p-qa__paragraph {
  margin-top: 1em;
}

