@use '../Global/' as *;

.c-totop {
  width: 100%;
  max-width: 1164px;
  margin-top: 80px;
  margin-inline: auto;
  padding-inline: 20px;

  @include MQ(md) {
    margin-top: 60px;
  }
}

.c-totop__button {
  width: 100px;
  height: 100px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  box-shadow: 0px 5px 5px rgba(165, 165, 165, 0.3);
  border-radius: 50%;
  transition: box-shadow .3s;

  &:hover {
    box-shadow: 0px 5px 5px rgba(165, 165, 165, 0.6);
  }

  img {
    width: 29px;

    @include MQ(md) {
      width: 18.4px;
    }
  }

  @include MQ(md) {
    width: 60px;
    height: 60px;
  }
}