:root {
  --mainColor: #41bdba;
  --accentColor: #F0EF8C;

  --u-color-50: #f2fbfa;
  --u-color-100: #d2f5f1;
  --u-color-200: #a5eae4;
  --u-color-300: #70d8d3;
  --u-color-400: #42D3C4;
  --u-color-500: #29a3a1;
  --u-color-600: #1f8082;
  --u-color-700: #1c6769;
  --u-color-800: #1b5254;
  --u-color-900: #1b4546;
  --u-color-950: #0a2629;

  --black: #333333;
  --white: #ffffff;
  --gray: #f4f4f4;
  --blue: #6BA4D8;
  --pink: #E18FC0;
  --lightYellow: #FFFD94;
}
