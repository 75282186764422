:root {
  // ratio
  --ratio: 1.5;
  --s-5: calc(var(--s-4) / var(--ratio));
  --s-4: calc(var(--s-3) / var(--ratio));
  --s-3: calc(var(--s-2) / var(--ratio));
  --s-2: calc(var(--s-1) / var(--ratio));
  --s-1: calc(var(--s0) / var(--ratio));
  --s0: 1rem;
  // 24
  --s1: calc(var(--s0) * var(--ratio));
  // 36
  --s2: calc(var(--s1) * var(--ratio));
  // 54
  --s3: calc(var(--s2) * var(--ratio));
  // 81
  --s4: calc(var(--s3) * var(--ratio));
  // 121.5
  --s5: calc(var(--s4) * var(--ratio));
  // font-size
  --font-size-small: 0.75rem;
  --font-size-smallish: 0.875rem;
  --font-size-base: var(--s0);
  --font-size-biggish: var(--s1);
  --font-size-big: var(--s2);
}
