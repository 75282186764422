@use '../Global/' as *;

.l-wrapper {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1064px;
  width: 100%;
}

.l-wrapper--index {
  margin-top: 80px;

  @include MQ('md') {
    margin-top: 40px;
  }
}

.l-wrapper--primary {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1046px;
  width: 100%;
}

.l-wrapper--secondary {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1032px;
  width: 100%;
}

.l-wrapper--large {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 1160px;
  width: 100%;
}

.l-wrapper--small {
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  max-width: 968px;
  width: 100%;
}
