@use '../Global/' as *;

.p-access {
  margin-top: 80px;

  @include MQ('md') {
    margin-top: 40px;
  }
}

.p-access__title {
  font-family: $font-familyLogo;
  font-size: rem(28);
  font-weight: 500;
  padding-left: 57px;
  position: relative;

  @include MQ('md') {
    font-size: rem(22);
    padding-left: 35px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 4px;
    transform: rotate(45deg);
    width: 18px;
    height: 18px;
    background-color: var(--accentColor);
  }
}

.p-access__list {
  margin-top: 48px;
  display: flex;
  flex-direction: column;
  row-gap: 56px;

  @include MQ('md') {
    margin-top: 24px;
    row-gap: 24px;
  }
}

.p-access__item {
  display: flex;
  align-items: flex-start;
  column-gap: 24px;

  @include MQ('md') {
    flex-direction: column;
    align-items: flex-start;
    row-gap: 14px;
  }
}

.p-access__label {
  width: 44.4444%;
  flex-shrink: 1.2;
  display: flex;
  flex-direction: column-reverse;

  @include MQ('md') {
    width: 100%;
  }
}

.p-access__name {
  font-family: $font-familyLogo;
  font-size: rem(24);
  font-weight: 500;

  @include MQ('md') {
    font-size: rem(18);
  }
}

.p-access__deco {
  font-family: $font-familyLato;
  font-size: rem(16);
  font-weight: 300;
}

.p-access__detail {
  width: 55.5556%;
  padding-top: 4px;

  @include MQ('md') {
    width: 100%;
    padding-top: 0;
    padding-left: 10px;
  }
}

.p-access__text {
  font-size: rem(20);
  line-height: 1.7;

  @include MQ('md') {
    font-size: rem(18);
  }
}

.p-access__link {
  border-bottom: 1px solid var(--black);
}

.p-access____map {
  margin-top: 64px;
  margin-bottom: 5px;
  width: 100%;
  height: 355px;
  box-shadow: 0px 5px 5px 0px rgba(165, 165, 165, 0.3);

  @include MQ('md') {
    margin-top: 40px;
    height: 248px;
  }

  iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

