@use '../Global/' as *;

.c-heading {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  padding-bottom: 24px;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100px;
    height: 2px;
    background-color: var(--mainColor);

    @include MQ('md') {
      width: 60px;
    }
  }
}

.c-heading__title {
  font-family: $font-familyLogo;
  font-size: rem(32);
  font-weight: 500;
  text-align: center;

  @include MQ('md') {
    font-size: rem(26);
  }
}

.c-heading__label {
  font-family: $font-familyLato;
  font-size: rem(20);
  font-weight: 500;
  color: var(--u-color-400);

  @include MQ('md') {
    font-size: rem(16);
  }
}
